import logger from 'lib/logger';
import schema from 'lib/schema';

const log = logger({ category: 'home' });

const Home = () => (
  <div>Home</div>
);

const getStaticProps = async () => {
  log.silly('getStaticProps');
  const results = {
    props: {}
  };
  results.props.schema = schema;
  return results;
};

export {
  getStaticProps,
};

export default Home;
